// code
import { Statistic } from '../types/Statistic';

// component
import { StoreSummaryCurrent } from './StoreSummaryCurrent';
import { StoreSummaryPast } from './StoreSummaryPast';

type Props = {
  statistic: Statistic;
};

export const StoreBody: React.FC<Props> = ({statistic}) => {
  return(
    <div className="flex">
      {
        statistic.length > 0 &&
          <>
            <StoreSummaryCurrent
              men={statistic[0].men}
              women={statistic[0].women}
              title={statistic[0].title}
              description={statistic[0].description}
            />
            <StoreSummaryPast
              statistic={statistic.slice(1)}
            />
          </>
      }
    </div>
  )
};
