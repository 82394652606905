// component
import { StoreRegionName } from "./StoreRegionName";
import { Store } from "./Store";

type Props = {
  storeStatics: [];
  selectedRegionId: number | undefined;
};

export const Stores: React.FC<Props> = ({storeStatics, selectedRegionId}) => {
  return(
    <>
      {
        storeStatics.map((region: any, i: number) => {
          return(
            <>
              {
                region.storesStatistics.length > 0 && (selectedRegionId === i || selectedRegionId === undefined) &&
                  <>
                    <StoreRegionName name={region.name} />
                    <div className="flex flex-wrap mb-10">
                      {
                        region.storesStatistics.map((storesStatistic: any) => {
                          return(
                            <Store
                              statistic={storesStatistic.statistics}
                              storeName={storesStatistic.storeName}
                              storePrefecture={storesStatistic.prefectureName}
                            />
                          )
                        })
                      }
                    </div>
                  </>
              }
            </>
          )
        })
      }
    </>
  )
};
