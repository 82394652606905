// component
import { RegionLink } from "./RegionLink";

type Props = {
  storeStatics: [];
  setSelectedRegionId: (regionId: number) => void;
};

export const RegionLinks: React.FC<Props> = ({storeStatics, setSelectedRegionId}) => {
  return(
    <div className="flex flex-wrap p-2">
      {
        storeStatics.map((region: any, i: number) => {
          return(
            <RegionLink
              regionId={i}
              name={region.name}
              setSelectedRegionId={setSelectedRegionId}
            />
          )
        })
      }
    </div>
  )
};
