type Props = {
  men: number;
  women: number;
  title: string;
  description: string;
};

export const StoreSummaryCurrent: React.FC<Props> = ({men, women, title, description}) => {
  return(
    <div className="p-3 pl-12 text-center w-1/2">
      <div className="font-bold">
        <div className="pr-1 text-gray-600" style={{whiteSpace: 'pre'}}>
          {title}
        </div>
        <span className="text-blue-600 text-xl">
          {men}
        </span>
        <span className="text-sm text-blue-600 mr-2">
          人
        </span>
        <span className="text-pink-600 text-xl">
          {women}
        </span>
        <span className="text-sm text-pink-600 mr-2">
          人
        </span>
      </div>
      <div className="font-bold text-gray-500 text-xs">
        {description}
      </div>
    </div>
  )
};
