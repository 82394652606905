type Props = {
  storeName: string;
  storePrefecture: string;
};

export const StoreHeader: React.FC<Props> = ({storeName, storePrefecture}) => {
  return(
    <div className="bg-white uppercase rounded-tl-lg border-b rounded-tr-lg p-2 flex">
      <div className="font-bold uppercase text-gray-600 ml-2">
        {storeName}
      </div>
      <div className="font-bold uppercase bg-gray-100 ml-auto mr-1 p-1 text-xs rounded text-gray-500">
        {storePrefecture}
      </div>
    </div>
  )
};
