type Props = {
  name: string;
  regionId: number;
  setSelectedRegionId: (regionId: number) => void;
};

export const RegionLink: React.FC<Props> = ({name, regionId, setSelectedRegionId}) => {
  return(
    <div className="w-1/2 md:w-1/3 p-1" onClick={() => setSelectedRegionId(regionId)}>
      <div className="bg-white rounded mx-1 my-1 py-3 shadow">
        <div className="flex flex-row items-center">
          <div className="flex-1 text-center md:text-center">
            <p className="text-sm font-bold uppercase text-gray-600">{name}</p>
          </div>
        </div>
      </div>
    </div>
  )
};
