// code
import { Statistic } from '../types/Statistic';

// component
import { StoreHeader } from './StoreHeader';
import { StoreBody } from './StoreBody';

type Props = {
  storeName: string;
  storePrefecture: string;
  statistic: Statistic;
};

export const Store: React.FC<Props> = ({storeName, storePrefecture, statistic}) => {
  return(
    <div className="w-full p-2 md:w-1/2 md:p-3 xl:w-1/3 xl:p-5">
      <div className="bg-white border-transparent rounded-lg shadow">
        <StoreHeader
          storeName={storeName}
          storePrefecture={storePrefecture}
        />
        <StoreBody
          statistic={statistic}
        />
      </div>
    </div>
  )
};
