// module
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider
} from "@apollo/client";

// component
import { Header } from "./page/Header"
import { Footer } from "./page/Footer"
import { Content } from "./page/Content"

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL,
  cache: new InMemoryCache()
});

export const App: React.FC = () => {
  return (
    <ApolloProvider client={client} >
      <Header />
      <Content />
      <Footer />
    </ApolloProvider>
  );
}
