// code
import { Statistic } from '../types/Statistic';

type Props = {
  statistic: Statistic;
};

export const StoreSummaryPast: React.FC<Props> = ({statistic}) => {
  const dataMaxLength = 3;
  return(
    <div className="my-auto w-1/2 text-center">
      <table className="font-bold text-xs p-1 mx-auto">
        <tbody>
          {
            statistic.slice(0, dataMaxLength).map((value) => {
              return(
                <tr>
                  <td className="text-gray-600 p-1" style={{whiteSpace: 'pre'}}>{value.title}</td>
                  <td className="pr-1">
                    <span className="text-blue-600">{value.men}</span>
                    <span className="text-blue-600 text-xs">人</span>
                  </td>
                  <td>
                    <span className="text-pink-600">{value.women}</span>
                    <span className="text-pink-600 text-xs">人</span>
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  )
};
