// module
import { useQuery, gql } from "@apollo/client";

const STORES_STATISTICS_ALL = gql`
  query { 
    storesStatisticsAll {
      name
      storesStatistics {
        storeName
        prefectureName
        status
        statistics {
          men
          women
          title
          description
          createdAt
        }
      }
    }
  }
`;

export const useStoresStatisticsAllQuery = (): any => {
  return useQuery(STORES_STATISTICS_ALL);
}
