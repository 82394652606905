export const Introduction: React.FC = () => {
  return(
    <div className="flex flex-col md:flex-row">
      <div className="w-full md:w-1/2 bg-gray-200 pb-5 pt-3 px-3 mb-3 mx-auto md:rounded md:text-center">
        <p className="font-bold text-gray-700 m-2">今宵 ~相席居酒屋~</p>
        <p className="text-gray-600 text-sm m-1">全国の相席居酒屋・相席ラウンジの情報をまとめたサイトです。</p>
        <p className="text-gray-600 text-sm m-1"> 各店舗の男女の来客数がリアルタイムに分かります。</p>
        <p className="text-gray-500 text-center text-xs mt-2"> (※各店舗が閉店の時間帯は前日の情報を表示します。)</p>
      </div>
    </div>
  )
};
