// module
import { useState } from "react";

// code
import { useStoresStatisticsAllQuery } from "../queries/StoresStatisticsAllQuery";

// component
import ReactLoading from "react-loading";
import { ContentAdjustContainer } from "../components/ContentAdjustContainer";
import { Introduction } from "../components/Introduction";
import { RegionLinks } from "../components/RegionLinks";
import { Stores } from "../components/Stores";

export const Content: React.FC = () => {
  const {loading, error, data} = useStoresStatisticsAllQuery();
  const [selectedRegionId, setSelectedRegionId] = useState<number | undefined>(undefined)

  return (
    <>
      <ContentAdjustContainer />
      <Introduction />
      {
        loading &&
          <ReactLoading 
            className="m-auto my-8"
            type="bars"
            color="grey"
          />
      }
      {
        error &&
          <p className="text-center m-12 font-bold">
            読み込み中にエラーが発生しました。
          </p>
      }
      {
        loading || error ||
          <>
            <RegionLinks
              storeStatics={data.storesStatisticsAll}
              setSelectedRegionId={setSelectedRegionId}
            />
            <Stores
              storeStatics={data.storesStatisticsAll}
              selectedRegionId={selectedRegionId}
            />
          </>
      }
    </>
  )
};
