export const Header: React.FC = () => {
  // ↓メニューの表示非表示を復活させるときはアンコメントする。
  // const [isNavOpen, setIsNavOpen] = useState<boolean>(false);

  return(
    <nav className="flex items-center justify-between flex-wrap bg-black py-3 px-4 fixed w-full z-50 top-0">
      <div
        className="flex items-center flex-shrink-0 text-white mr-6 cursor-pointer"
        onClick={() => { window.location.reload()} }
      >
        <h1 className="text-xl font-bold md:nl-3 font-title">今宵　~相席居酒屋~</h1>
      </div>

      {/* ↓メニューの表示非表示を復活させるときはアンコメントする。 */}
      {/* <div className="block lg:hidden">
        <button
          id="nav-toggle"
          className="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-white hover:border-white"
          onClick={() => {setIsNavOpen(!isNavOpen)}}
        >
          <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
          </svg>
        </button>
      </div> */}

      {/* {
        isNavOpen &&
          <div className="w-full flex-grow lg:flex lg:items-center lg:w-autolg:block pt-6 lg:pt-0" id="nav-content">
            <ul className="list-reset lg:flex justify-end flex-1 items-center text-white">
              <li className="mr-3 py-2 px-4">
                相席屋一覧
              </li>
              <li className="mr-3 py-2 px-4">
                機能A
              </li>
              <li className="mr-3 py-2 px-4">
                機能B
              </li>
              <li className="mr-3 py-2 px-4">
                機能C
              </li>
            </ul>
          </div>
      } */}
    </nav>
  )
}