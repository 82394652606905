type Props = {
  name: string;
}

export const StoreRegionName: React.FC<Props> = ({name}) => {
  return(
    <h3 className="font-bold text-gray-700 text-center text-xl my-5">
      {name}
    </h3>
  )
};
